@import "~react-pro-sidebar/dist/scss/styles.scss";
body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.mainSection {
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    margin-top: 35px;
  }
}
.color-red {
  color: red;
}

.text-center {
  text-align: center;
}

.my-1 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.my-2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mt-4 {
  margin-top: 20px;
}

.m-2 {
  margin: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.nav {
  padding: 16px;
  background: #e9f1f2;
  @media (max-width: 768px) {
    margin: 15px 16px 0 16px;
  }
}

.bg-color-primary {
  background-color: rgb(6, 106, 252);
}

.color-primary {
  color: rgb(6, 106, 252);
}

.color-light-grey {
  color: #9e9e9e;
}

.w-50 {
  width: 50%;
}

.w-content {
  width: fit-content;
}

.back-btn {
  button {
    padding: 6px;
    height: 36px;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.font-bold {
  font-weight: bold;
}

.font-size-28 {
  font-size: 28px;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.gap-1 {
  gap: 1rem;
}

.filter-field-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.flex-grow {
  flex-grow: 1;
}

.rmsc .dropdown-heading {
  height: 36px !important;
  cursor: pointer !important;
}

.avatar {
  cursor: pointer;
}

.maaglim-logo {
  max-height: 50px;
}

.filter-fields > div {
  width: 19%;
  max-width: 19%;
}

.filter-fields > button {
  width: 5%;
  max-width: 5%;
  margin-top: 16px;
}

@media (max-width: 767px) {
  .filter-fields {
    flex-direction: column;
    align-items: unset !important;
  }

  .filter-fields > div {
    width: 100%;
    max-width: 100%;
  }

  .filter-fields > button {
    width: 10%;
    max-width: 10%;
    margin-top: unset;
    align-self: end;
  }
}

.table-scrollbar {
  max-height: calc(100vh - 360px);
  height: calc(100vh - 360px);
  overflow: auto;
}

.custom-table {
  thead tr th {
    align-items: center;
    text-align: center;
  }
  td {
    align-items: center;
    text-align: center;
    .dropzone-inner-container {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      img {
        height: auto;
        max-width: 30px;
      }
    }
  }
}
