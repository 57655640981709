.verify-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

@media screen and (min-width: 480px) {
  .child-container {
    width: 400px;
  }
  .verify-container {
    font-size: 18px;
  }
}

.resend-btn {
  background: none;
  padding: 0;
  border: none;
  margin-top: 10px;
  color: rgb(26, 101, 173);
  cursor: pointer;
}
