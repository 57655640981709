.verify-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

@media screen and (min-width: 480px) {
  .child-container {
    width: 400px;
    padding: 20px;
    border: thin solid lightgray;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  }
  .verify-container {
    font-size: 18px;
  }
}

.verify-options-label label {
  font-size: 17px;
  font-weight: 500;
}

.confirm-btn button {
  max-width: 350px;
}
