.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 480px) {
  .child-container {
    width: 400px;
  }
}

.input-group {
  margin-bottom: 1rem;
}

.login-btn {
  margin-top: 30px;
}
