.dropzone-inner-container {
  padding: 20px !important;
}
.dropzone-container {
  position: relative;
  margin-block: 15px;
}
.mantine-2q1ayn {
  padding: 0 !important;
}
.close-style {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  cursor: pointer;
  height: 10px;
}
.dropzone-image-container {
  border: 2px dashed #ced4da;
  padding: 5px !important;
}
.image-style {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
.align-item-center {
  align-items: center
}

.flex{
  display: flex;
}
.flex-wrap{
flex-wrap: wrap;
}