body {
  margin: 0;
  height: 100vh;
  font-family: sans-serif;
}

#root {
  height: 100%;
}
.pro-sidebar {
  color: #000 !important;
  > .pro-sidebar-inner {
    background: #fff;
    box-shadow: 4px 0px 10px -4px rgba(0, 0, 0, 0.62);
  }
  .pro-menu {
    .pro-menu-item {
      > .pro-inner-item {
        padding: 12px;
      }
      &.active {
        background: #e9f1f2;
        color: #000;
      }
    }
  }
  &.collapsed {
    width: 60px;
    min-width: 60px;
  }
}

.app {
  height: 100vh;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    display: none;
    margin-bottom: 10px;
  }
  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: #000;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    @media (max-width: 768px) {
      padding: 24px;
    }

    header {
      margin-bottom: 16px;
      border-bottom: 1px solid #efefef;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  @media (max-width: 768px) {
    .btn-toggle {
      display: block;
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }
}
